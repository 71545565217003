import { useState } from "react";
import { Icon } from "@iconify-icon/react";

type AccordionProps = {
  id: number | string;
  title: string;
} & React.PropsWithChildren;

export function Accordion({ id, title, children }: AccordionProps) {
  const [isOpen, setOpen] = useState(false);

  function handleToggle(e: React.SyntheticEvent<HTMLDetailsElement>) {
    e.preventDefault();
    const detailsEl = e.target as HTMLDetailsElement;
    setOpen(detailsEl.open);
  }

  return (
    <details
      key={id}
      open={isOpen}
      onClick={(e) => !isOpen && setOpen(true)}
      onToggle={handleToggle}
      className={`
      p-6 sm:p-8 rounded-xl transition-all
      ${
        isOpen
          ? "shadow-[0_0_0_1px_var(--gray-80)]"
          : "hover:bg-fsc-gray-90 bg-fsc-gray-95 cursor-pointer"
      }
      `}>
      <summary
        onClick={(e) => e.stopPropagation()}
        className={`
        list-none
        flex flex-col sm:flex-row sm:items-center justify-between
        gap-4 ${isOpen && "mb-4 cursor-pointer"}
        `}>
        <h4 className="text-lg sm:text-xl/normal text-fsc-gray-20 font-semibold">
          {title}
        </h4>
        <div className="flex items-center gap-2 font-semibold text-fsc-primary-blue group">
          <span>{isOpen ? "collapse" : "expand"}</span>
          <Icon icon={isOpen ? "baseline-minus" : "material-symbols:add"} />
        </div>
      </summary>

      <div
        className={`
        transition-all
        ${isOpen ? "opacity-100" : "opacity-0"}
      `}>
        {children}
      </div>
    </details>
  );
}
